import api from '@/libs/axios'

const uploadEndpoint = '/chat-file-upload'
const chatQueueListEndpoint = '/chat_queue/list'
const chatRoomEndpoint = '/doctor/chat_room/add/user'
const chatRoomStatusEndpoint = '/doctor/chat_room/change/status'
const chatRoomDetail = '/doctor/chat_room/data/detail'
const chatRoomList = '/doctor/chat_room/list'
const chatQueueChangeEndpoint = '/chat_queue/change/status'
const chatRoomStatusEndpointLogout = '/doctor/chat_room/change/status/logout'
const chatEndEndpoint = '/doctor/chat_room/end'

const service = {
    getAll: async (params) => {
        try {
            const result = await api.get(chatQueueListEndpoint, { params: params })
            return result.data.data
        } catch {
            return null
        }
    },

    fetchChatRoomList: async (params) => {
        try {
            const result = await api.get(chatRoomList, { params: params })
            return result.data
        } catch (error) {
            return null
        }
    },

    getClientUserData: async (params) => {
        try {
            const result = await api.get(chatRoomList, { params: params })
            return result.data.data
        } catch (error) {
            return null
        }
    },

    fetchChatRoomDetail: async (params) => {
        try {
            const result = await api.get(chatRoomDetail, { params: params })
            return result.data.data
        } catch (error) {
            console.log(error)
            return null
        }
    },

    createNewChatRoom: async (params) => {
        try {
            const result = await api.post(chatRoomEndpoint, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result.data.data
        } catch {
            return null
        }
    },

    changeRoomStatus: async (params) => {
        try {
            const result = await api.post(chatRoomStatusEndpoint, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result.data.data
        } catch {
            return null
        }
    },
    changeRoomStatusLogout: async (params) => {
        try {
            const result = await api.post(chatRoomStatusEndpointLogout, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result.data.data
        } catch {
            return null
        }
    },

    changeChatQueueStatus: async (params) => {
        try {
            const result = await api.post(chatQueueChangeEndpoint, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result.data.data
        } catch {
            return null
        }
    },

    uploadFile: async (params) => {
        try {
            const result = await api.post(uploadEndpoint, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result.data
        } catch {
            return null
        }
    },

    chatEnd: async (params) => {
        try {
            const result = await api.post(chatEndEndpoint, params)
            return result.data
        } catch {
            return null
        }
    }
}

export { service }
