import api from '@/libs/axios'
import qs from 'qs'

const endpoint = {
    dashboard: '/dashboard',
    doctor_attendance: '/doctor_attendance/current',
    chatQueueChangeEndpoint: '/chat_queue/change/status'
}

const service = {
    getAttendance: async () => {
        try {
            const result = await api.get(endpoint.doctor_attendance)
            return result.data
        } catch {
            return null
        }
    },
    addAttendance: async (params) => {
        try {
            const result = await api.post(`${endpoint.doctor_attendance}/${params.type}`, params)
            return result.data
        } catch {
            return null
        }
    },
    getAlldatasdashboard: async (params) => {
        try {
            const result = await api.get(endpoint.dashboard, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllchatlist: async (params) => {
        try {
            const result = await api.get(`${endpoint.dashboard}/chat-list`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            })
            return result.data
        } catch {
            return null
        }
    },

    changeChatQueueStatus: async (params) => {
        try {
            const result = await api.post(endpoint.chatQueueChangeEndpoint, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result.data.data
        } catch {
            return null
        }
    }
}

export { service }
