import { defineStore } from 'pinia'
import { service } from './service'

export const useStore = defineStore({
    id: 'useDashboardStore',
    state: () => ({
        attendanceResponse: null,
        addAttendanceResponse: null,
        getAllDataCountResponse: null,
        getAllChatListResponse: null
    }),

    getters: {
        getAttendanceResponse(state) {
            return state.attendanceResponse
        },
        getAddAttendanceResponse(state) {
            return state.addAttendanceResponse
        },
        getAlldashboarddata(state) {
            return state.getAllDataCountResponse
        },
        getAllchatlistdata(state) {
            return state.getAllChatListResponse
        }
    },

    actions: {
        async fetchAttendance() {
            const response = await service.getAttendance()
            this.attendanceResponse = response
        },
        async addAttendance(params) {
            const response = await service.addAttendance(params)
            this.addAttendanceResponse = response
        },
        async fetchDataCount(params) {
            const response = await service.getAlldatasdashboard(params)
            this.getAllDataCountResponse = response
        },
        async fetchChatList(params) {
            const response = await service.getAllchatlist(params)
            this.getAllChatListResponse = response
        },
        async changeChatQueueStatus(param) {
            await service.changeChatQueueStatus(param)
        }
    }
})
